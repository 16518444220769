import React, {useEffect} from "react";
import {CssBaseline, useMediaQuery} from "@mui/material";
// import StarFrame from "../../elements/StarFrame";
import CambridgeFrame from "../../elements/CambridgeFrame";
import VideoFrame from "./VideoFrame";
import ReserveNPricingLinkFrame from "./ReserveNPricingLinkFrame";
import WorldClassFrame from "./WorldClassFrame";
import PartOfCambridgeFrame from "./PartOfCambridgeFrame";
import TransitioningFrame from "./TransitioningFrame";
import EnrollFrame from "../../elements/EnrollFrame";
import FooterFrame from "../../elements/FooterFrame";
import CardsFrame from "./CardsFrame";
import HeroSection from "../../elements/HeroSection";
import theme from "../../elements/Theme";


const MainPage: React.FC = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <CssBaseline/>
            <HeroSection
                mainTitle={
                    <>
                        Give your child a<br/>
                        head start in life
                    </>
                }
                secondaryTitle={
                    <>
                        {isMobile ? "✅ " : ""}A world-leading primary school academic program <br/>
                        {isMobile ? "✅ " : ""}A multicultural, language-immersive environment <br/>
                        {isMobile ? "✅ " : ""}A comprehensive curriculum for holistic development
                    </>
                }
                backgroundImageUrl={'/homePage/hero.png'}
                buttonText={'RESERVE A SPOT'}
                buttonOnClickPath={'enroll'}
                underButtonText='Limited spots available.'
            />
            {/*<StarFrame/>*/}
            <CambridgeFrame/>
            <VideoFrame/>
            <ReserveNPricingLinkFrame/>
            <WorldClassFrame/>
            <CardsFrame/>
            <PartOfCambridgeFrame/>
            <TransitioningFrame/>
            <EnrollFrame/>
            <FooterFrame/>
        </>
    )
}

export default MainPage;