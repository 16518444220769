import React, {useEffect} from "react";
import {Box, CssBaseline, useMediaQuery} from "@mui/material";
import PricingFrame from "./PricingFrame";
import EnrollFrame from "../../elements/EnrollFrame";
import FooterFrame from "../../elements/FooterFrame";
import HeroSection from "../../elements/HeroSection";
import CambridgeFrame from "../../elements/CambridgeFrame";
import theme from "../../elements/Theme";

const PricingPage: React.FC = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <CssBaseline/>
            <HeroSection
                mainTitle={
                    <>
                        More for your child
                    </>
                }
                secondaryTitle={
                    <>
                        {isMobile ? "✅ " : ""}World-class curriculum and empathetic teachers<br/>
                        {isMobile ? "✅ " : ""}Small classes for a more personalized learning experience<br/>
                        {isMobile ? "✅ " : ""}Unrivaled set of activities and value-packed extras for your child<br/>
                    </>
                }
                backgroundImageUrl={'/pricingPage/hero.png'}
            />
            <Box my={'5vh'}>
                <CambridgeFrame/>
            </Box>

            <PricingFrame/>
            <EnrollFrame/>
            <FooterFrame/>
        </>
    )
}

export default PricingPage;