import { Route, Router } from "react-router-dom";
import { HeadProvider } from "react-head";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@mui/material/styles";
import MainPage from "./pages/mainPage/MainPage";
import ApproachPage from "./pages/approachPage/ApproachPage";
import ProgramPage from "./pages/programPage/ProgramPage";
import PricingPage from "./pages/pricingPage/PricingPage";
import EnrollPage from "./pages/enrollPage/EnrollPage";
import SEO from "./elements/SEO";
import theme from "./elements/Theme";
import "./assets/fonts.css";
import "normalize.css";

const history = createBrowserHistory();

function App() {
  return (
    <HeadProvider>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <SEO>
            <Route exact path="/" component={MainPage} />
            <Route path="/approach" component={ApproachPage} />
            <Route path="/our-program" component={ProgramPage} />
            <Route path="/pricing" component={PricingPage} />
            <Route path="/enroll" component={EnrollPage} />
          </SEO>
        </Router>
      </ThemeProvider>
    </HeadProvider>
  );
}

export default App;
