export const initialValues = {
  initial_title: "Young Sparrows | Quality Education for Children",
  initial_description:
    "A world-leading primary school academic program. A multicultural, language-immersive environment. A comprehensive curriculum for holistic development.",
};

export const pages = [
  {
    route: "/",
    title: "Young Sparrows | Quality Education for Children",
    description:
      "A world-leading primary school academic program. A multicultural, language-immersive environment. A comprehensive curriculum for holistic development.",
  },
  {
    route: "/approach",
    title: "Why Choose Young Sparrows | Quality Education for Children",
    description:
      "Learning, as it should be. Cultivating curiosity, creativity, and independent thinking in an inclusive environment - we set cornerstones for lifelong growth.",
  },
  {
    route: "/our-program",
    title:
      "Academic Programs at Young Sparrows | Quality Education for Children",
    description:
      "Education for the future. Our program lays exceptional academic foundations, supporting your child’s well-rounded development.",
  },
  {
    route: "/pricing",
    title: "Pricing at Young Sparrows | Quality Education for Children",
    description:
      "World-class curriculum and empathetic teachers. Small classes for a more personalized learning experience. Unrivaled set of activities and value-packed extras for your child",
  },
  {
    route: "/enroll",
    title: "Enrollment at Young Sparrows | Quality Education for Children",
    description:
      "A world-leading primary school academic program. A multicultural, language-immersive environment. A comprehensive curriculum for holistic development.",
  },
];
