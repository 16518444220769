import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Title, Meta } from "react-head";
import { initialValues, pages } from "../utils/seo";

interface SEOProps {
  children: React.ReactNode;
}

const SEO: React.FC<SEOProps> = ({ children }) => {
  const location = useLocation();
  const { initial_title, initial_description } = initialValues;

  const [title, setTitle] = useState(initial_title);
  const [description, setDescription] = useState(initial_description);

  useEffect(() => {
    const route = location.pathname;
    let page = pages.find((page) => page.route === route);
    let title = page && page.title;
    let description = page && page.description;

    title && setTitle(title);
    description && setDescription(description);
  }, [location.pathname]);

  return (
    <>
      <Title>{title}</Title>
      <Meta name="description" content={description} />
      {children}
    </>
  );
};

export default SEO;
